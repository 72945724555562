import styled from 'styled-components';
import { rem } from 'polished';
import { Link } from '@village/ui';

const Container = styled.div`
    display: block;
    padding: ${rem(16)} ${rem(20)};
    background-color: ${({ theme }) => theme.vui.colors.background.surface.secondary.default};
`;

const LocationLink = styled(Link)`
    text-decoration: underline;
`;

export { Container, LocationLink };
