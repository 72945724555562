import { FC, PropsWithChildren } from 'react';
import { Dialog } from '@material-ui/core';
import { ArrowBackIcon, CloseIcon } from '@village/icons';

import * as Styled from './styles';

interface Props {
    isOpen: boolean;
    handleBack?: () => void;
    handleClose: () => void;
}

const DialogModal: FC<PropsWithChildren<Props>> = ({ handleBack, handleClose, isOpen, children }) => {
    return (
        <Dialog fullScreen open={isOpen} onClose={handleClose}>
            <Styled.DialogToolbar className="dialog-toolbar">
                <Styled.ButtonIcon
                    color="inherit"
                    onClick={handleBack}
                    disableFocusRipple={true}
                    disableTouchRipple={true}
                    aria-label="back"
                    $isVisible={!!handleBack}
                >
                    <ArrowBackIcon />
                </Styled.ButtonIcon>
                <Styled.ButtonIcon
                    color="inherit"
                    onClick={handleClose}
                    disableFocusRipple={true}
                    disableRipple={true}
                    aria-label="close"
                    $isVisible={true}
                >
                    <CloseIcon />
                </Styled.ButtonIcon>
            </Styled.DialogToolbar>
            {children}
        </Dialog>
    );
};

export { DialogModal };
