import styled from 'styled-components';
import { rem } from 'polished';

import { Banner as BannerComponent } from 'components/banner';

const Banner = styled(BannerComponent)`
    align-items: flex-start;
    border-radius: 0;
`;

const FormContainer = styled.section`
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
    width: 100%;
    padding-top: ${rem(40)};

    & > form {
        width: 100%;
        flex-direction: column;
        margin: 0;
    }
`;

const WarningContainer = styled.div`
    margin: 2.5rem 0;
`;

export { Banner, FormContainer, WarningContainer };
