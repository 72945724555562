import { Text, TextProps } from '@village/ui';
import { FC } from 'react';
import styled from 'styled-components';

const StepTitleStyled = styled(Text)`
    color: ${({ theme }) => theme.vui.colors.text.brand.default};
    font-family: CeraProBold, sans-serif, Arial;
`;

export const StepTitle: FC<Omit<TextProps, 'type'>> = ({ children, ...props }) => {
    return (
        <StepTitleStyled type="h4" fontWeight={700} {...props} className="citymd-step-title">
            {children}
        </StepTitleStyled>
    );
};
