import { Divider } from '@village/ui';
import { FC, useEffect, useRef } from 'react';
import { isEmpty, keys, toPairs, values } from 'ramda';

import { CollapsibleAppointmentGroup } from 'citymd/components/appointment-group/collapsible-appointment-group';
import { groupAppointmentsByDate } from 'citymd/utils/appointment';
import { useAppointments, useBookingSteps, useIsDepartmentEnabled } from 'citymd/hooks';
import { CenteredSpinner } from 'components/centered-spinner';
import { NoAvailability } from 'citymd/components/no-availability';
import { useAppOptions, useBooking } from 'hooks';
import * as Styled from './styles';

const AllAvailability: FC = () => {
    const { booking, setBookingField } = useBooking();
    const { currentStep, goForward } = useBookingSteps();
    const isDepartmentEnabled = useIsDepartmentEnabled();
    const options = useAppOptions<'allCitymdSchedulers'>();
    const { data: appointments, isLoading: isAppointmentsLoading } = useAppointments(booking, {
        enabled: isDepartmentEnabled === 'ENABLED',
    });
    const appointmentsByDate = groupAppointmentsByDate(appointments);
    const noAvailability = values(appointmentsByDate).every((value) => isEmpty(value));
    const isAppointmentsAvailabilityStep = currentStep === 'APPOINTMENTS_AVAILABILITY';
    const openModalOnloadRef = useRef(options.openModalOnload);

    useEffect(() => {
        setBookingField({ appointment: undefined });
    }, [setBookingField]);

    useEffect(() => {
        if (openModalOnloadRef.current && !currentStep) {
            goForward();
            openModalOnloadRef.current = false;
        }
    }, [currentStep, goForward]);

    if (isAppointmentsLoading) {
        return <CenteredSpinner />;
    }

    if (noAvailability) {
        return <NoAvailability isOpen={options.isOpen} onlineBookingUnavailable={true} />;
    }

    return (
        <Styled.Container>
            {toPairs(appointmentsByDate).map(([date, dateAppointments], index) => (
                <Styled.Container key={date}>
                    <Styled.Wrapper $isModalView={isAppointmentsAvailabilityStep}>
                        <CollapsibleAppointmentGroup
                            date={date}
                            appointments={dateAppointments}
                            isCollapsingEnabled={isAppointmentsAvailabilityStep}
                        />
                    </Styled.Wrapper>
                    {index < keys(appointmentsByDate).length - 1 ? <Divider /> : null}
                </Styled.Container>
            ))}
        </Styled.Container>
    );
};

export { AllAvailability };
