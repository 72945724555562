import { Text } from '@village/ui';
import { FC } from 'react';

import { formatPhoneNumber } from 'utils/phone-number';
import { formatStreetAddress } from 'utils/address';
import { toTitleCase } from 'utils/string';
import { useAppOptions } from 'hooks';
import * as Styled from './styles';

const PatientDetailsCard: FC = () => {
    const options = useAppOptions<'authenticated-citymd'>();
    const { address, dateOfBirth, email, firstName, lastName, homePhone, genderIdentity } = options.patient;

    const items = [
        {
            title: 'Patient name',
            lines: [
                [firstName ? toTitleCase(firstName) : null, lastName ? toTitleCase(lastName) : null].filter(Boolean).join(' '),
            ],
        },
        {
            title: 'Date of birth',
            lines: [dateOfBirth.replace(/\//g, '.')],
        },
        {
            title: 'Legal Sex',
            lines: [genderIdentity === 'F' ? 'Female' : 'Male'],
        },
        {
            title: 'Home address',
            lines: address
                ? [
                      address.address1 ? formatStreetAddress(address.address1, address.address2) : null,
                      [address.city, address.state, address.zip].filter(Boolean).join(' '),
                  ]
                : [],
        },
        {
            title: 'Email',
            lines: [email],
        },
        {
            title: 'Contact number',
            lines: homePhone ? [formatPhoneNumber(homePhone)] : [],
        },
    ];

    return (
        <Styled.Container>
            {items.map((item) => (
                <div key={item.title}>
                    <Styled.ItemTitle>{item.title}</Styled.ItemTitle>
                    {item.lines.map((line, index) => (
                        <Text key={index} type="body1">
                            {line}
                        </Text>
                    ))}
                </div>
            ))}
        </Styled.Container>
    );
};

export { PatientDetailsCard };
