import { MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { FC, PropsWithChildren } from 'react';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';

import { theme } from './theme';
import { CustomGlobalStyles } from './custom-global-styles';

const ThemeProvider: FC<PropsWithChildren> = ({ children }) => {
    return (
        <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
                <StyledComponentThemeProvider theme={theme}>
                    {children}
                    <CustomGlobalStyles />
                </StyledComponentThemeProvider>
            </MuiThemeProvider>
        </StylesProvider>
    );
};

export { ThemeProvider };
