import { Text } from '@village/ui';
import { FC } from 'react';
import { AxiosError } from 'axios';

import { useAppOptions, useErrorBoundaryMonitoring } from 'hooks';
import * as Styled from './styles';

interface Props {
    resetErrorBoundary: () => void;
    error: AxiosError | Error;
}

const ErrorBoundaryFallback: FC<Props> = ({ resetErrorBoundary, error }) => {
    useErrorBoundaryMonitoring(error);
    const { page } = useAppOptions<'citymd'>();
    const isLocationListView = page === 'location-list';

    return (
        <Styled.Container data-testid="error-boundary-screen" $isLocationListView={isLocationListView}>
            {!isLocationListView ? <Styled.TopBar /> : null}
            <Styled.Wrapper>
                <Styled.Content>
                    <Styled.Title>Oops! Something went wrong.</Styled.Title>
                    <Text type="body1">We're working on fixing the issue. If the issue persists, please give us a call.</Text>
                </Styled.Content>
                <Styled.Button size="medium" onClick={resetErrorBoundary}>
                    Retry
                </Styled.Button>
            </Styled.Wrapper>
        </Styled.Container>
    );
};

export { ErrorBoundaryFallback };
