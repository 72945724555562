import styled from 'styled-components';
import { Text } from '@village/ui';
import { rem } from 'polished';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(24)};
`;

const ItemTitle = styled(Text).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
`;

export { Container, ItemTitle };
