import { Text } from '@village/ui';
import { Fragment } from 'react';

import { ClockAnalog, Info, ShieldWithHeart } from 'citymd/icons';
import { useBookingSteps } from 'citymd/hooks';
import * as Styled from './styles';
import { StepTitle } from 'citymd/components/step-title';

const KnowBeforeYouGo = () => {
    const { goForward } = useBookingSteps();

    const sections = [
        {
            icon: <Info />,
            header: 'For emergencies, call 911 Immediately',
            copy: (
                <Fragment>
                    If you’re experiencing severe chest pain, difficulty breathing, or any life-threatening situation, please call{' '}
                    <Styled.Link data-testid="link-911" href="tel:+1-911">
                        911
                    </Styled.Link>
                    .
                </Fragment>
            ),
        },
        {
            icon: <ShieldWithHeart />,
            header: 'Verify your insurance is accepted',
            copy: (
                <Fragment>
                    Check the CityMD website for a list of accepted insurance. For further questions, please call your carrier.
                </Fragment>
            ),
        },
        {
            icon: <ClockAnalog />,
            header: 'Appointment time not guaranteed',
            copy: 'Please note that scheduling does not guarantee an appointment time; patients with urgent medical needs will be prioritized first.',
        },
    ];

    return (
        <Styled.Container>
            <Fragment>
                <Styled.DetailsContainer>
                    <Styled.Header>
                        <StepTitle>What to know before you go</StepTitle>
                    </Styled.Header>
                    {sections.map(({ header, icon, copy }) => (
                        <Styled.StackRow key={header}>
                            {icon}
                            <Styled.StackCol>
                                <Styled.Headline type="body1" fontWeight={700}>
                                    {header}
                                </Styled.Headline>
                                <Text type="body1" fontWeight={400}>
                                    {copy}
                                </Text>
                            </Styled.StackCol>
                        </Styled.StackRow>
                    ))}
                </Styled.DetailsContainer>
                <Styled.ButtonWrapper>
                    <Styled.Button onClick={() => goForward()} size="large">
                        Okay
                    </Styled.Button>
                </Styled.ButtonWrapper>
            </Fragment>
        </Styled.Container>
    );
};

export { KnowBeforeYouGo };
