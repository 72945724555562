import styled from 'styled-components';
import { rem } from 'polished';

const Container = styled.div<{ $includeHeader?: boolean }>`
    width: 100%;
    ${({ $includeHeader }) => $includeHeader === false && `height: 100%;`}
`;

const Content = styled.div<{ $includeHeader?: boolean }>`
    max-width: ${rem(720)};
    margin: 0 auto;
    padding: ${rem(40)} ${rem(20)};
    ${({ $includeHeader }) => $includeHeader === false && `height: 100%;`}
`;

export { Container, Content };
