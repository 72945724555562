import { FC } from 'react';

import * as Styled from './styles';
import { Appointment } from 'types';
import { useBooking, useCaptureCountlyEvent } from 'hooks';
import { useBookingSteps } from 'citymd/hooks';

interface Props {
    appointments: Appointment[];
    slotsToDisplay: number;
}

const AppointmentGroup: FC<Props> = ({ appointments, slotsToDisplay }) => {
    const { setBookingField } = useBooking();
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { goForward, currentStep } = useBookingSteps();

    const handleSelectAppointment = (appointment: Appointment) => {
        setBookingField({ appointment });
        addCountlyEvent({
            key: 'selectSlot',
            segmentation: {
                appointmentId: appointment.appointmentid,
                appointmentTypeId: appointment.appointmenttypeid,
                appointmentDate: appointment.date,
                time: appointment.starttime,
            },
        });
        goForward();
    };

    return (
        <Styled.SlotsWrapper>
            {appointments.slice(0, slotsToDisplay).map((appointment) => (
                <Styled.SlotButton
                    key={appointment.appointmentid}
                    fullWidth={true}
                    variant="tertiary"
                    size="medium"
                    onClick={() => handleSelectAppointment(appointment)}
                    $isModalView={currentStep === 'APPOINTMENTS_AVAILABILITY'}
                >
                    {appointment.displayStartTime}
                </Styled.SlotButton>
            ))}
        </Styled.SlotsWrapper>
    );
};

export { AppointmentGroup };
