import { Text, Button as VuiButton } from '@village/ui';
import styled from 'styled-components';
import { rem } from 'polished';

import { device } from 'citymd/constants/device';

const Container = styled.div<{ $isLocationListView: boolean }>`
    background-color: ${({ theme }) => theme.vui.colors.background.white};
    width: ${({ $isLocationListView }) => ($isLocationListView ? '100%' : rem(482))};
    box-shadow: ${({ $isLocationListView }) => ($isLocationListView ? 'none' : '0 5px 40px rgba(0, 0, 0, 0.1)')};

    @media ${device.maxTablet} {
        width: auto;
        box-shadow: none;
    }
`;

const TopBar = styled.div`
    height: ${rem(8)};
    background-color: ${({ theme }) => theme.vui.colors.icon.default};

    @media ${device.maxTablet} {
        display: none;
    }
`;

const Wrapper = styled.div`
    padding: ${rem(24)} ${rem(40)} ${rem(40)};
    display: inline-flex;
    flex-direction: column;
    gap: ${rem(24)};

    @media ${device.maxTablet} {
        max-width: ${rem(335)};
        padding: ${rem(40)} 0;
    }
`;

const Content = styled.div`
    display: inline-flex;
    flex-direction: column;
    gap: ${rem(12)};
`;

const Title = styled(Text).attrs(() => ({ type: 'h3' }))`
    /* overrides Drupal CMS styling */
    margin-bottom: 0 !important;
`;

const Button = styled(VuiButton)`
    height: ${rem(48)};
    width: ${rem(126)};
`;

export { Container, Wrapper, Content, Button, TopBar, Title };
