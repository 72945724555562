import styled from 'styled-components';
import { Divider as VuiDivider, Text } from '@village/ui';
import { rem } from 'polished';

import { Image } from 'components/image';
import { device } from 'citymd/constants/device';

const Container = styled.div`
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    scroll-margin-top: ${rem(78)};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: ${rem(24)} ${rem(20)};
    max-width: ${rem(720)};
    width: 100%;

    @media ${device.maxMobile} {
        align-items: flex-start;
    }
`;

const LocationImage = styled(Image)`
    border-radius: 50%;
    border: none;
    object-fit: cover;
    height: ${rem(80)};
    width: ${rem(80)};
    margin-right: ${rem(16)};

    @media ${device.maxMobile} {
        height: ${rem(48)};
        width: ${rem(48)};
    }
`;

const BoldInfo = styled(Text).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
`;

const AddressContainer = styled.div`
    display: flex;
    align-items: center;
    column-gap: ${rem(8)};
`;

const Address = styled.span`
    margin-right: ${rem(8)};
`;

const Divider = styled(VuiDivider).attrs(() => ({ as: 'span' }))`
    display: inline-flex;
    width: ${rem(2)};
    height: ${rem(12)};
    margin-right: ${rem(8)};
`;

const OpenStatus = styled.span.attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.success};
    font-family: CeraProBold, arial, sans-serif;
`;

const WaitTimeContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${rem(8)};
`;

const WaitTime = styled.span.attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.link.secondary.default};
    font-family: CeraProBold, arial, sans-serif;
`;

const ClosedStatus = styled.span.attrs(() => ({ type: 'body1' }))`
    color: ${({ theme }) => theme.vui.colors.text.link.secondary.default};
    font-family: CeraProBold, arial, sans-serif;
`;

export {
    Address,
    AddressContainer,
    BoldInfo,
    ClosedStatus,
    Container,
    Divider,
    LocationImage,
    OpenStatus,
    WaitTime,
    WaitTimeContainer,
    Wrapper,
};
