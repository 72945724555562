import { FC, PropsWithChildren } from 'react';

import { ModalHeader } from 'citymd/components/modal-header';
import * as Styled from './styles';

interface HeaderProps {
    includeHeader?: boolean;
}

const ModalViewWrapper: FC<PropsWithChildren<HeaderProps>> = ({ children, includeHeader = true }) => {
    return (
        <Styled.Container $includeHeader={includeHeader}>
            {includeHeader && <ModalHeader />}
            <Styled.Content $includeHeader={includeHeader}>{children}</Styled.Content>
        </Styled.Container>
    );
};

export { ModalViewWrapper };
