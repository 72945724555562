import { FC } from 'react';

import * as Styled from './styles';

interface Props {
    isOpen?: boolean;
    isToday?: boolean;
    onlineBookingUnavailable?: boolean;
}

const NoAvailability: FC<Props> = ({ isOpen, isToday, onlineBookingUnavailable }) => {
    /**
     * Location is closed
     */
    if ((isToday && !isOpen) || (onlineBookingUnavailable && !isOpen)) {
        return (
            <Styled.Container>
                This CityMD location is currently closed. Please try another{' '}
                <Styled.LocationLink href="https://www.citymd.com/urgent-care-locations">location</Styled.LocationLink> or check
                back on one of our open days.
            </Styled.Container>
        );
    }

    /**
     * Location is open but online booking is unavailable (No availability in the next 3 days)
     */
    if (onlineBookingUnavailable) {
        return <Styled.Container>Online booking is currently unavailable. Please walk-in. Thank you!</Styled.Container>;
    }

    /**
     * No availability for today but location is open
     */
    if (isToday) {
        return (
            <Styled.Container>Online booking is currently unavailable today. Please try another day or walk-in.</Styled.Container>
        );
    }

    return (
        <Styled.Container>
            Online booking is currently unavailable on this day. Please try another day or walk-in during our business hours.
        </Styled.Container>
    );
};

export { NoAvailability };
