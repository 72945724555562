import { IllnessIcon, MedicalBagIcon, InjuryIcon, VehicleAccidentIcon, TruckIcon } from 'citymd/icons';
import { Booking } from 'types';

export const reasonKeyToText = {
    ILLNESS_OR_INJURY: 'Regular urgent care',
    OCCUPATIONAL_MEDICINE: 'Employer Referred (Occupational Medicine)',
    WORKERS_COMP_INJURY: 'Work Related Injury',
    MOTOR_VEHICLE_ACCIDENT: 'Motor Vehicle Accident',
    DOT_PHYSICAL: 'DOT Physical',
} as const;

export const reasonsForVisit = [
    {
        text: reasonKeyToText.ILLNESS_OR_INJURY,
        value: 'ILLNESS_OR_INJURY',
        icon: <IllnessIcon />,
    },
    {
        text: reasonKeyToText.OCCUPATIONAL_MEDICINE,
        value: 'OCCUPATIONAL_MEDICINE',
        icon: <MedicalBagIcon />,
    },
    {
        text: reasonKeyToText.WORKERS_COMP_INJURY,
        value: 'WORKERS_COMP_INJURY',
        icon: <InjuryIcon />,
    },
    {
        text: reasonKeyToText.MOTOR_VEHICLE_ACCIDENT,
        value: 'MOTOR_VEHICLE_ACCIDENT',
        icon: <VehicleAccidentIcon />,
    },
    {
        text: reasonKeyToText.DOT_PHYSICAL,
        value: 'DOT_PHYSICAL',
        icon: <TruckIcon />,
    },
] as const;

export const getReasonAppointmentNote = ({
    firstTimeWorkersCompInjuryCheck,
    reasonForVisit,
}: Pick<Booking, 'firstTimeWorkersCompInjuryCheck' | 'reasonForVisit'>) => {
    if (reasonForVisit === 'WORKERS_COMP_INJURY') {
        return firstTimeWorkersCompInjuryCheck ? 'WC Initial' : 'WC F/U';
    }

    if (reasonForVisit === 'MOTOR_VEHICLE_ACCIDENT') {
        return 'NF';
    }

    if (reasonForVisit === 'DOT_PHYSICAL' || reasonForVisit === 'OCCUPATIONAL_MEDICINE') {
        return 'OccMed';
    }

    return 'UC New';
};
