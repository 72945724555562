import { useBooking, useCaptureCountlyEvent } from 'hooks';
import { useBookingSteps } from 'citymd/hooks';
import { StepTitle } from '../step-title';
import * as Styled from './styles';

const options = ['Yes', 'No'] as const;

const WorkersCompInjuryCheck = () => {
    const { setBookingField, booking } = useBooking();
    const { goForward } = useBookingSteps();
    const { addCountlyEvent } = useCaptureCountlyEvent();

    const handleOptionSelect = (firstTimeWorkersCompInjuryCheck: 'Yes' | 'No') => {
        setBookingField({ firstTimeWorkersCompInjuryCheck: firstTimeWorkersCompInjuryCheck === 'Yes' });
        addCountlyEvent({ key: 'firstTimeWorkersCompInjuryCheck', segmentation: { firstTimeWorkersCompInjuryCheck } });
        goForward();
    };

    return (
        <Styled.Container>
            <StepTitle>Is this your first visit at CityMD for this injury?</StepTitle>
            <Styled.Wrapper>
                {options.map((option) => (
                    <Styled.ButtonCard
                        $isPreSelected={booking.firstTimeWorkersCompInjuryCheck === (option === 'Yes')}
                        pictureSize="small"
                        key={option}
                        onClick={() => handleOptionSelect(option)}
                    >
                        {option}
                    </Styled.ButtonCard>
                ))}
            </Styled.Wrapper>
        </Styled.Container>
    );
};

export { WorkersCompInjuryCheck };
