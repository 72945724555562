import styled from 'styled-components';

import { Banner as BannerComponent } from 'components/banner';

const Banner = styled(BannerComponent)`
    align-items: flex-start;
    border-radius: 0;
`;

export { Banner };
