import { Button as VuiButton, Link as VuiLink, Text } from '@village/ui';
import styled from 'styled-components';
import { rem } from 'polished';

import { device } from 'citymd/constants/device';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
    scroll-margin-top: ${rem(94)};
`;

const DateHeading = styled(Text).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
`;

const Link = styled(VuiLink).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
    color: inherit;
    text-decoration: underline;

    @media (hover: hover) {
        &:hover {
            text-decoration: none;
        }
    }
`;

const SlotsWrapper = styled.div`
    display: inline-flex;
    gap: ${rem(12)};
    flex-wrap: wrap;
    width: auto;
`;

// The Drupal site causes some odd styling issues on buttons,
// so be careful when modifying the properties below.
const Button = styled(VuiButton)`
    padding: 0;
    height: ${rem(48)};
    word-wrap: break-word;
    border-width: 1.6px;

    @media (hover: hover) {
        &:hover {
            border-width: 1.6px;
        }
    }
`;

/**
 *  The width of the button changes depending on the view and screen size:
 *    - Desktop:
 *      - If modal is open, 160px
 *      - Else, 126px
 *    - Mobile:
 *      - Always 103px
 */
const SlotButton = styled(Button)<{ $isModalView: boolean }>`
    width: ${({ $isModalView }) => ($isModalView ? rem(160) : rem(126))};

    @media ${({ $isModalView }) => ($isModalView ? device.maxMobile : device.maxTablet)} {
        width: ${rem(103)};
    }
`;

export { Button, Link, SlotButton, SlotsWrapper, Container, DateHeading };
