import { Text } from '@village/ui';
import styled from 'styled-components';
import { rem } from 'polished';

import { Image } from 'components/image';
import {
    AppointmentChangesIcon as AppointmentChanges,
    VisitGuidelinesIcon as VisitGuidelines,
    PreVisitFormsIcon as PreVisitForms,
} from 'citymd/icons';
import { device } from 'citymd/constants/device';

const Header = styled.div`
    display: flex;
    padding: ${rem(64)} ${rem(20)} 0;
    scroll-margin-top: ${rem(78)};

    @media ${device.maxTablet} {
        padding-top: ${rem(48)};
    }
`;

const AppointmentDetailsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${rem(24)};
    margin: auto;
`;

const AppointmentDetails = styled(Text).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(40)};
    margin: auto;
    max-width: ${rem(720)};
    padding: ${rem(48)} ${rem(20)};

    @media ${device.maxTablet} {
        padding: ${rem(40)} ${rem(20)};
    }
`;

const DateAndLocation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Address = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ReasonForVisit = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.vui.colors.text.secondary};
`;

const Directions = styled.div`
    display: flex;
    gap: ${rem(4)};
    margin: ${rem(10)} auto 0;
    align-items: center;
`;

const DirectionsLink = styled.a`
    display: flex;
    gap: ${rem(4)};
    font-family: CeraProBold, arial, sans-serif;
    text-decoration: underline;

    @media (hover: hover) {
        &:hover {
            text-decoration: none;
        }
    }
`;

const PreVisitFormsIcon = styled(PreVisitForms)`
    color: ${({ theme }) => theme.vui.colors.icon.default};
`;

const VisitGuidelinesIcon = styled(VisitGuidelines)`
    color: ${({ theme }) => theme.vui.colors.icon.default};
`;

const AppointmentChangesIcon = styled(AppointmentChanges)`
    color: ${({ theme }) => theme.vui.colors.icon.default};
`;

const List = styled.ul`
    padding-left: ${rem(24)};
`;

const LocationImage = styled(Image)`
    border-radius: 50%;
    border: none;
    object-fit: cover;
    height: ${rem(80)};
    width: ${rem(80)};
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(24)};
    border: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    padding: ${rem(32)};
`;

const Content = styled.div`
    display: grid;
    gap: ${rem(24)};
`;

const HeadingContainer = styled.div`
    display: flex;
    gap: ${rem(8)};
    margin-bottom: ${rem(16)};
    font-family: CeraProBold, arial, sans-serif;
`;

const HeadingTitle = styled(Text).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
`;

export {
    Address,
    AppointmentDetailsContainer,
    AppointmentDetails,
    CardContainer,
    Content,
    DateAndLocation,
    Directions,
    DirectionsLink,
    Header,
    HeadingContainer,
    HeadingTitle,
    AppointmentChangesIcon,
    VisitGuidelinesIcon,
    PreVisitFormsIcon,
    List,
    LocationImage,
    DetailsWrapper,
    ReasonForVisit,
};
