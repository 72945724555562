import styled from 'styled-components';
import { rem } from 'polished';

import { device } from 'citymd/constants/device';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const Wrapper = styled.div<{ $isModalView: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media ${({ $isModalView }) => ($isModalView ? device.maxMobile : device.maxTablet)} {
        max-width: 20.9375rem;
    }
`;

const NoAppointmentsCard = styled.div`
    display: inline-flex;
    flex-direction: column;
    padding: ${rem(16)} ${rem(8)};
    gap: ${rem(12)};
    background-color: ${({ theme }) => theme.vui.colors.background.surface.secondary.default};
`;

export { Container, Wrapper, NoAppointmentsCard };
