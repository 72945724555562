import { FC, Fragment, useContext, useEffect, useRef } from 'react';
import { Text } from '@village/ui';

import * as Styled from './styles';
import { BookingStepsContext } from 'citymd/contexts/booking-steps';
import { formatStreetAddress } from 'utils/address';
import { getRelativeDateHeader } from 'citymd/utils/date';
import { useAppOptions, useBooking } from 'hooks';

const ModalHeader: FC = () => {
    const {
        departmentAddress: { address1, address2, city, state, zip },
        departmentName,
        imageUrl,
        waitTime,
        isOpen,
    } = useAppOptions<'citymd'>();
    const {
        booking: { appointment },
    } = useBooking();
    const bookingSteps = useContext(BookingStepsContext);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (bookingSteps && containerRef.current) {
            containerRef.current.scrollIntoView({ block: 'start' });
        }
    }, [bookingSteps]);

    if (appointment) {
        return (
            <Styled.Container ref={containerRef}>
                <Styled.Wrapper>
                    <Styled.LocationImage src={imageUrl} alt={departmentName} />
                    <div>
                        <Text type="body1">{departmentName}</Text>
                        <Styled.BoldInfo>{getRelativeDateHeader(appointment.dateTimeParsed, 'appointmentDate')}</Styled.BoldInfo>
                    </div>
                </Styled.Wrapper>
            </Styled.Container>
        );
    }

    return (
        <Styled.Container ref={containerRef}>
            <Styled.Wrapper>
                <Styled.LocationImage src={imageUrl} alt={departmentName} />
                <div>
                    <Styled.BoldInfo>{departmentName}</Styled.BoldInfo>
                    <Styled.AddressContainer>
                        {address1 ? (
                            <Text type="body1">
                                <Styled.Address>
                                    {formatStreetAddress(address1, address2)}, {city}, {state} {zip}
                                </Styled.Address>
                                {isOpen ? (
                                    <Fragment>
                                        <Styled.Divider direction="vertical" />
                                        <Styled.OpenStatus>Open</Styled.OpenStatus>
                                    </Fragment>
                                ) : null}
                            </Text>
                        ) : null}
                    </Styled.AddressContainer>
                    <Styled.WaitTimeContainer>
                        {isOpen && waitTime ? (
                            <Text type="body1">
                                Walk-in est. wait time:{' '}
                                <Styled.WaitTime>{`${waitTime < 5 ? '<5' : waitTime} min`}</Styled.WaitTime>
                            </Text>
                        ) : null}
                    </Styled.WaitTimeContainer>
                    {isOpen === false ? <Styled.ClosedStatus>Closed</Styled.ClosedStatus> : null}
                </div>
            </Styled.Wrapper>
        </Styled.Container>
    );
};

export { ModalHeader };
