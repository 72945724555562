import { createGlobalStyle } from 'styled-components';

const CustomGlobalStyles = createGlobalStyle`
    html {
        font-family: CeraProRegular; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
        scroll-behavior: smooth;
    }

    div[id^="open-appointments-"] h3,
    div[id^="open-appointments-"] h4,
    #root h3,
    #root h4 {
        margin: 0;
    }
`;

export { CustomGlobalStyles };
