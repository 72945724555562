import { rem } from 'polished';
import styled from 'styled-components';
import { Checkbox as VuiCheckbox } from '@village/ui';
import { ReactNode } from 'react';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
`;

const Checkbox = styled(VuiCheckbox)<{ $isChecked: boolean; icon: ReactNode; checkedIcon: ReactNode }>`
    &.MuiFormControlLabel-root {
        gap: ${rem(12)};
    }

    svg {
        width: ${rem(20)};
        height: ${rem(20)};
        color: ${({ theme, $isChecked }) =>
            $isChecked ? theme.vui.colors.icon.default : theme.vui.colors.border.default.default};
    }
`;

export { Wrapper, Checkbox };
