import { Toolbar } from '@material-ui/core';
import { ButtonIcon as VuiButtonIcon } from '@village/ui';
import styled from 'styled-components';
import { rem } from 'polished';

const DialogToolbar = styled(Toolbar)`
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    justify-content: space-between;
    padding: 0 ${rem(12)};
    min-height: ${rem(78)};
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: ${({ theme }) => theme.vui.colors.background.surface.default};
`;

const ButtonIcon = styled(VuiButtonIcon)<{ $isVisible: boolean }>`
    visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
    background: none;
    padding: 0;
    width: auto;
    height: 0;
`;

export { ButtonIcon, DialogToolbar };
