import styled from 'styled-components';
import { rem } from 'polished';
import { Button } from '@village/ui';

import { RadioValuesContainer, RadioInput } from 'components/fields/radio-form/styles';
import { device } from 'citymd/constants/device';

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${rem(24)};
`;

const FieldsGroup = styled.section`
    display: flex;
    flex-direction: column;
    row-gap: ${rem(20)};

    label {
        font-family: CeraProBold, arial, sans-serif;
        padding-left: 0;
    }

    input {
        caret-color: ${({ theme }) => theme.vui.colors.text.default};
    }

    @media (hover: hover) {
        .MuiOutlinedInput-root:hover:not(.Mui-focused),
        .MuiOutlinedInput-root.Mui-focused:hover {
            color: ${({ theme }) => theme.vui.colors.text.default};
        }
    }

    input[type='email'],
    input[type='text'] {
        border: none;
    }

    .MuiInputBase-root.MuiOutlinedInput-root > .MuiInputBase-input {
        max-width: 100%;
        text-indent: 0;
        height: ${rem(48)};
        color: ${({ theme }) => theme.vui.colors.text.default};
    }

    ${RadioInput} {
        font-family: CeraProRegular; /* stylelint-disable-line font-family-no-missing-generic-family-keyword */
        padding: 0 ${rem(20)};
        color: ${({ theme }) => theme.vui.colors.text.default};

        & .Mui-checked {
            color: ${({ theme }) => theme.vui.colors.icon.default};
        }
    }
    ${RadioValuesContainer} {
        border-color: ${({ theme }) => theme.vui.colors.border.tertiary.default};
        border-radius: 0;
    }

    .MuiRadio-root {
        color: ${({ theme }) => theme.vui.colors.border.default.default};

        .MuiSvgIcon-root {
            height: ${rem(20)};
            width: ${rem(20)};
        }
    }

    input[type='number'] {
        appearance: textfield;
    }

    input[type='number']::-webkit-outer-spin-button,
    input[type='number']::-webkit-inner-spin-button {
        appearance: none;
    }
`;

const FieldsGroupsContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${rem(48)};
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: ${rem(40)};
`;

const SubmitButton = styled(Button)`
    margin: 0 auto;
    width: ${rem(217)};
    height: ${rem(48)};

    @media ${device.maxMobile} {
        width: 100%;
        max-width: ${rem(341)};
        height: ${rem(56)};
    }
`;

export { Fields, FieldsGroup, FieldsGroupsContainer, FormContainer, SubmitButton };
