import { forwardRef } from 'react';
import type { SvgIconProps } from '@village/icons';
import { SvgIcon } from '@village/icons';

export const AppointmentChangesIcon = forwardRef<SVGSVGElement, SvgIconProps>((props, ref) => (
    <SvgIcon ref={ref} viewBox="0 0 24 24" data-testid="note-text-outlined-icon" {...props}>
        <path
            d="M9.7 18.7L8.3 17.3L10.6 15L8.3 12.7L9.7 11.3L12 13.6L14.3 11.3L15.7 12.7L13.4 15L15.7 17.3L14.3 18.7L12 16.4L9.7 18.7ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
            fill="currentColor"
        />
    </SvgIcon>
));
