import { Text } from '@village/ui';
import { PersonOutlinedIconDeprecated } from '@village/icons';

import { PatientIdentification as PatientIdentificationType } from 'types';
import { useAppOptions, useBooking, useCaptureCountlyEvent } from 'hooks';
import { useBookingSteps } from 'citymd/hooks';
import { StepTitle } from 'citymd/components/step-title';
import * as Styled from './styles';

const PatientIdentification = () => {
    const { goForward } = useBookingSteps();
    const { setBookingField, booking } = useBooking();
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { patient } = useAppOptions<'authenticated-citymd'>();

    const handleOptionSelect = (patientIdentification: PatientIdentificationType) => {
        setBookingField({ patientIdentification });
        addCountlyEvent({ key: 'selectPatientIdentification', segmentation: { patientIdentification } });
        goForward();
    };

    return (
        <Styled.Container>
            <StepTitle>Who are you scheduling for?</StepTitle>
            <Styled.Wrapper>
                <Styled.ButtonCard
                    key="self"
                    $isPreSelected={booking.patientIdentification === 'self'}
                    picture={
                        !patient.firstName || patient.lastName ? (
                            <Styled.AvatarInitials>
                                <Text type="h5" fontWeight={700}>
                                    {patient.firstName?.[0]}
                                    {patient.lastName?.[0]}
                                </Text>
                            </Styled.AvatarInitials>
                        ) : (
                            <PersonOutlinedIconDeprecated />
                        )
                    }
                    pictureSize="normal"
                    onClick={() => handleOptionSelect('self')}
                >
                    <Text type="sub1" fontWeight={700}>
                        {patient.firstName} {patient.lastName}
                    </Text>
                    {patient.dateOfBirth ? <Styled.DateOfBirth type="body1">{patient.dateOfBirth}</Styled.DateOfBirth> : null}
                </Styled.ButtonCard>

                <Styled.Banner icon={null}>
                    <Text type="body1">Scheduling for someone else? Visit CityMD.com to book for family, or friends.</Text>
                </Styled.Banner>
            </Styled.Wrapper>
        </Styled.Container>
    );
};

export { PatientIdentification };
