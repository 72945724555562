import { createContext } from 'react';

import { bookingSteps } from './booking-steps';

export interface BookingStepsContextType<State = unknown> {
    readonly currentStep: (typeof bookingSteps)[number]['key'] | undefined;
    readonly state: State | undefined;
    readonly goBack: (options?: { state: State }) => void;
    readonly goForward: (options?: { state: State }) => void;
    readonly goToStart: () => void;
}

const BookingStepsContext = createContext<BookingStepsContextType | undefined>(undefined);

BookingStepsContext.displayName = 'BookingStepsContext';

export { BookingStepsContext, bookingSteps };
