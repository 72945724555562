import { ButtonCard as VuiButtonCard } from '@village/ui';
import { rem } from 'polished';
import styled from 'styled-components';

const Container = styled.div`
    display: inline-flex;
    flex-direction: column;
    gap: ${rem(24)};
    width: 100%;
`;

const Wrapper = styled.div`
    display: inline-flex;
    flex-direction: column;
    gap: ${rem(16)};
`;

const ButtonCard = styled(VuiButtonCard)<{ $isPreSelected: boolean }>`
    font-family: CeraProBold, arial, sans-serif;
    align-content: center;
    height: ${rem(72)};
    border: 2px solid
        ${({ theme, $isPreSelected }) =>
            $isPreSelected ? theme.vui.colors.border.tertiary.active : theme.vui.colors.border.tertiary.default};
    border-radius: ${({ theme }) => theme.vui.component.buttonCard.default.borderRadius};
    padding: ${rem(24)} ${rem(20)};

    & svg {
        color: ${({ theme }) => theme.vui.colors.icon.default};
    }

    @media (hover: hover) {
        &:hover {
            border-color: ${({ theme }) => theme.vui.colors.border.tertiary.hover};
        }
    }

    &:active {
        border-color: ${({ theme }) => theme.vui.colors.border.tertiary.active};
    }
`;

export { Container, ButtonCard, Wrapper };
