import { mergeDeepLeft } from 'ramda';
import { DefaultTheme } from 'styled-components';
import { rem, rgba } from 'polished';

import { theme as citymdTheme } from 'citymd/theme';

const SUMMIT_BRAND = '#2355EC';
const CITYMD_BRAND = '#D80032';
const BLACK = '#000000';
const WHITE = '#FFFFFF';

const theme: DefaultTheme = mergeDeepLeft(
    {
        vui: {
            platform: 'web',
            component: {
                button: {
                    size: {
                        medium: {
                            borderRadius: rem(1000),
                            fontSize: rem(16),
                            lineHeight: '1.5',
                        },
                    },
                    variant: {
                        primary: {
                            default: {
                                background: SUMMIT_BRAND,
                                border: 'transparent',
                                font: WHITE,
                            },
                            hover: {
                                background: '#26419D',
                                border: 'transparent',
                                font: WHITE,
                            },
                            pressed: {
                                background: '#0F1E89',
                                border: 'transparent',
                                font: WHITE,
                            },
                        },
                        secondary: {
                            default: {
                                border: SUMMIT_BRAND,
                                font: SUMMIT_BRAND,
                            },
                            hover: {
                                background: '#FFF1F1',
                                border: SUMMIT_BRAND,
                                font: SUMMIT_BRAND,
                            },
                            pressed: {
                                background: SUMMIT_BRAND,
                                border: SUMMIT_BRAND,
                                font: SUMMIT_BRAND,
                            },
                        },
                        tertiary: {
                            default: {
                                background: WHITE,
                                border: rgba(SUMMIT_BRAND, 0.3),
                                font: SUMMIT_BRAND,
                            },
                            hover: {
                                background: '#F7F9FF',
                                border: rgba(SUMMIT_BRAND, 0.3),
                                font: SUMMIT_BRAND,
                            },
                            pressed: {
                                border: rgba(SUMMIT_BRAND, 0.3),
                                background: '#E5EFFF',
                                font: SUMMIT_BRAND,
                            },
                        },
                        link: {
                            default: {
                                background: 'transparent',
                                font: SUMMIT_BRAND,
                            },
                            hover: {
                                background: '#FFF1F1',
                                font: SUMMIT_BRAND,
                            },
                            pressed: {
                                background: '#FBDCDE',
                                font: SUMMIT_BRAND,
                            },
                        },
                    },
                },
                buttonCard: {
                    default: {
                        contentColor: BLACK,
                        borderColor: BLACK,
                        borderRadius: rem(8),
                    },
                    hover: {
                        contentColor: BLACK,
                        borderColor: BLACK,
                        border: BLACK,
                        borderRadius: rem(8),
                    },
                },
                buttonIcon: {
                    variant: {
                        default: {
                            color: CITYMD_BRAND,
                        },
                        hover: {
                            color: '#A30026',
                            background: WHITE,
                        },
                        pressed: {
                            color: '#6F001A',
                            background: WHITE,
                        },
                    },
                },
                input: {
                    default: {
                        default: {
                            borderColor: '#868787',
                            textColor: BLACK,
                        },
                        hover: {
                            borderColor: BLACK,
                        },
                        focus: {
                            borderColor: BLACK,
                        },
                    },
                },
                spinner: {
                    color: SUMMIT_BRAND,
                },
            },
            colors: {
                background: {
                    blue: '#F6F6F6',
                    surface: {
                        default: WHITE,
                        secondary: {
                            default: '#F9F9F9',
                        },
                        critical: {
                            default: '#FFF1F1',
                        },
                    },
                },
                border: {
                    default: {
                        default: '#868787',
                        pressed: BLACK,
                    },
                    tertiary: {
                        default: '#D6D7D9',
                        hover: rgba(SUMMIT_BRAND, 0.3),
                        active: rgba(SUMMIT_BRAND, 0.3),
                    },
                    divider: {
                        default: '#F1F2F4',
                    },
                },
                action: {
                    primary: {
                        default: BLACK,
                        hovered: BLACK,
                        pressed: '#515151',
                    },
                },
                icon: {
                    default: BLACK,
                },
                text: {
                    default: BLACK,
                    success: '#326937',
                    secondary: '#6C6C6C',
                    link: {
                        default: SUMMIT_BRAND,
                        secondary: {
                            default: BLACK,
                        },
                    },
                    critical: {
                        default: SUMMIT_BRAND,
                    },
                    brand: {
                        default: '#0F1E89',
                    },
                },
            },
            typography: {
                web: {
                    h3: {
                        fontSize: rem(28),
                        lineHeight: 1.14,
                        fontWeight: 700,
                    },
                    h4: {
                        fontSize: rem(24),
                        lineHeight: 1.14,
                        fontWeight: 700,
                    },
                    body1: {
                        fontSize: rem(16),
                        lineHeight: 1.5,
                        fontWeight: 400,
                    },
                    caption1: {
                        fontSize: rem(14),
                        lineHeight: 1.43,
                        fontWeight: 700,
                    },
                    sub1: {
                        fontSize: rem(16),
                        lineHeight: 1.5,
                        fontWeight: 700,
                    },
                },
            },
        },
        overrides: {
            MuiButton: {
                root: {
                    fontFamily: 'CeraProBold',
                    transition: 'none',
                },
            },
            MuiFormControlLabel: {
                root: {
                    fontFamily: 'CeraProBold',
                    padding: '0',
                },
            },
            MuiOutlinedInput: {
                root: {
                    border: 'none',
                    borderRadius: 0,
                    fontFamily: 'CeraProRegular',
                },
                input: {
                    borderRadius: 0,
                    padding: `${rem(8)} ${rem(12)}`,
                },
            },
            MuiRadio: {
                root: {
                    color: '#868787',
                    borderColor: '#D6D7D9',
                },
            },
        },
    },
    citymdTheme
);

export { theme };
