import styled from 'styled-components';
import { rem } from 'polished';
import { Button as VuiButton, Text, Link as VuiLink } from '@village/ui';

import { device } from 'citymd/constants/device';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(40)};
`;

const Button = styled(VuiButton)`
    margin: 0 auto;
    width: ${rem(217)};
    height: ${rem(48)};

    @media ${device.maxMobile} {
        width: 100%;
        max-width: ${rem(341)};
        height: ${rem(56)};
    }
`;

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(24)};
`;

const Link = styled(VuiLink).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
    color: inherit;
    text-decoration: underline;

    @media (hover: hover) {
        &:hover {
            text-decoration: none;
        }
    }
`;

const ErrorHeading = styled(Text).attrs(() => ({ type: 'h3' }))`
    /* overrides Drupal CMS styling */
    margin-bottom: 0 !important;
`;

const ErrorWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(24)};
`;

const ErrorContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${rem(12)};
`;

const NewVisitButton = styled(Button)`
    margin: 0;
`;

export { Container, Button, HeadingWrapper, DetailsContainer, Link, ErrorHeading, ErrorWrapper, ErrorContent, NewVisitButton };
